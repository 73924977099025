import { Col, Container, Row } from "react-bootstrap";
import { InnerHeader, PaySteps } from "../../common";
import React, { useLayoutEffect } from "react";

const PayNow = ({ history }) => {
  const { consumer_number, payMethod } = history.location || {};

  useLayoutEffect(() => {
    if (!payMethod) history.goBack();
  }, [payMethod])

  return payMethod ?
    <div className="">
      <InnerHeader history={history} visible={true} />
      <Container>
        <Row>
          <Col>
            <h1 className="fontSize18 fontWeight700 darkGrayColor marBot20 marTop20">
              {payMethod == 'easypaisa' ? 'Easypaisa' : 'Bank'} se payment ba-asaani
            </h1>

            <PaySteps payMethod={payMethod} consumer_number={consumer_number} />

          </Col>
        </Row>
      </Container>
    </div>
    : null
}

export default PayNow;