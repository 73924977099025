import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";
import { toast } from 'react-toastify';
import { data } from "./data";
import React from "react";

const PaySteps = ({ payMethod, consumer_number }) => {

    const handleVideo = () => {
        logEvent(analytics, `payment_link_method_${payMethod}_see_how`, {});
        window.open(payMethod == 'easypaisa' ? 'https://www.youtube.com/shorts/zfYKxzTPFkE' : 'https://www.youtube.com/watch?v=rEwb7mYPmZY');
    };

    const onCopy = async () => {
        try {
            await navigator.clipboard.writeText(consumer_number);
            logEvent(analytics, `payment_link_method_${payMethod}_copy_id`, {});
            toast.success("Copied");
        } catch (error) { }

    };

    return (
        <div className="detailScreenCardMain">
            {data[payMethod].map((item, index) => {
                const { stepTitle, stepIcon, stepNo, link, copy } = item;
                return (
                    <div className="detailScreenCardInner" key={index}>
                        <h1 className="fontSize14 fontWeight700 darkGrayColor marBot15">
                            {stepNo}
                        </h1>
                        <div className="detailScreenCard">

                            <div className="detailScreenCardLeft">
                                <img src={stepIcon} alt="" title="" />
                            </div>

                            <div className="detailScreenCardRight">
                                {copy && consumer_number ? (
                                    <>
                                        <p className="detailCardCutomerBox">
                                            <span className="fontSize12 fontWeight700 darkGrayTwoColor">
                                                {stepTitle}
                                            </span>
                                            <span className="fontSize14 fontWeight500 darkGrayColor">
                                                {consumer_number}
                                            </span>
                                        </p>
                                        <p>
                                            <span className="customerIdAction" onClick={onCopy}>
                                                COPY
                                            </span>
                                        </p>
                                    </>
                                ) : link ? (
                                    <p>
                                        <span className="fontSize14 fontWeight500 darkGrayColor">
                                            {stepTitle}
                                        </span>
                                        <span className="fontSize14 fontWeight500 barclayBlueColor marLeft5" onClick={handleVideo}>
                                            (See how)
                                        </span>
                                    </p>
                                ) : (
                                    <p>
                                        <span className="fontSize14 fontWeight500 darkGrayColor">
                                            {stepTitle}
                                        </span>
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                )
            })}
            {/* <VideoModal show={show} handleClose={toggleModal} /> */}
        </div>
    );
};

export default PaySteps;